
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="court" class="col-12">
      <h5 class="mb-3">Court Details</h5>
      <div class="card mb-4 overflow-hidden">
        <div class="card-body">
          <div class="row">
            <div class="col-10 col-sm-8">
              <div class="d-flex align-items-center justify-content-start mb-3">
                <div class="">
                  <h4 class="lh-base mb-1">{{court.name}}</h4>
                  <p v-if="court.description" class="mb-0 pt-1 text-muted">{{court.description}}</p>
                </div>
              </div>
            </div>
            <div class="col-2 col-sm-4 text-end">
              <div class="dropdown no-arrow">
                <a class="dropdown-toggle btn btn-sm btn-light" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a @click="popupModalShow = true" class="dropdown-item"
                     href="javascript:void(0)" >
                    Update court </a>
                  </li>
                  <li>
                    <a @click.prevent="deleteItem()"
                    class="dropdown-item" href="javascript:void(0)">
                    Delete court </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12">
              <div class="row g-3">
                <div class="col-sm-6">
                    <label class="form-label fw-bold">Court number</label>
                    <div>{{court.number}}</div>
                </div>
                <div class="col-sm-6">
                    <label class="form-label fw-bold">Court category</label>
                    <div v-if="court.category">{{court.category.title}}</div>
                </div>
                <div class="col-12">
                    <label class="form-label fw-bold">Location/Address</label>
                    <div>{{court.location}}</div>
                </div>
                <div class="col-12 col-md-4">
                    <label class="form-label fw-bold">Local Government</label>
                    <div v-if="court.lga">{{court.lga.name}}</div>
                </div>
                <div class="col-6 col-md-4">
                    <label class="form-label fw-bold">State</label>
                    <div v-if="court.state">{{court.state.name}}</div>
                </div>
                <div class="col-6 col-md-4">
                    <label class="form-label fw-bold">Country</label>
                    <div v-if="court.country">{{court.country.name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
  <b-modal v-model="popupModalShow" hide-footer centered
    title="Update court"
    title-class="fs-6" @hide="initializeAll()">
    <court-form v-if="popupModalShow" @closeMe="initializeAll()"
    :editItem="court" :editMode="true" @updatedItem="updateItem($event)"/>
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import CourtForm from '@/components/forms/CourtForm.vue';

export default {
  name: "court-show",
  components:{
    IsLoading,
    CourtForm
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      court: null
    }
  },
  watch: {
    '$route.params.courtId': function (id){
      if(id) this.fetchItem()
    },
  },
  methods: {
    updateItem(item){
      Object.assign(this.court, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/courts/${this.$route.params.courtId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.court = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem(){
      this.confirmDelete({
        text: "You want to delete an court permanently",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/courts/${this.court.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path: '/courts'})
            }
          })
        }
      });
    },
    initializeAll(){
      this.popupModalShow = false;
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

