
<template>
<form autocomplete="off" class="">
    <div class="row gx-lg-4 g-3">
    <div class="col-12">
        <label class="form-label" for="inputName">Court Name</label>
        <input id="inputName" type="text" class="form-control" v-model="data.name"
        :class="{ 'is-invalid': v$.data.name.$error }" placeholder="Name" name="name" />
        <div v-if="v$.data.name.$error"
        class="invalid-feedback">
        <span v-if="v$.data.name.required.$message">
            {{ v$.data.name.required.$message }}</span>
        </div>
    </div>
    <div class="col-md-6">
        <label class="form-label" for="inputNumber">Court Number</label>
        <input id="inputNumber" autocomplete="false" type="text" class="form-control" v-model="data.number"
        placeholder="Court number" name="number" />
    </div>
    <div class="col-md-6">
        <label class="form-label">Category</label>
        <select class="form-select" v-model="data.court_category_id" :class="{ 'is-invalid': v$.data.court_category_id.$error  }"
            name="input-category" placeholder="--select category--">
            <option disabled value="" key="-1">-- select category--</option>
            <option :value="category.id" v-for="(category, catIndex) in categoryList" 
            :key="catIndex">{{ category.title }}</option>
        </select>
        <div v-for="(item, index) in v$.data.court_category_id.$errors"
        :key="index" class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
        </div>
    </div>
    <div class="col-sm-12">
        <label for="location" class="form-label"> Court Location/Police Station </label>
        <input v-model="data.location" id="location" name="location"
        class="form-control" type="text" placeholder="Address">
    </div>
    <div class="col-md-4">
        <label for="country_id" class="form-label">Country</label>
        <select @change="countryChange()" v-model="data.country_id" 
            name="country_id" id="country_id" class="form-select">
        <option disabled value="" >--choose country--</option>
        <option v-for="(country, coIndex) in countryList" :key="coIndex" 
        :value="country.id">{{country.name}}</option>
        </select>
    </div>
    <div class="col-md-4">
        <label for="state_id" class="form-label">State</label>
        <select @change="stateChange()" v-model="data.state_id" 
        name="state_id" id="state_id" class="form-select">
        <option disabled value="" >--choose state--</option>
        <option v-for="(state, sIndex) in stateOptions" :key="sIndex" 
        :value="state.id">{{state.name}}</option>
        </select>
    </div>
    <div class="col-md-4">
        <label for="local_government_id" class="form-label">Local Government</label>
        <select v-model="data.local_government_id" name="local_government_id" id="local_government_id" class="form-select">
        <option disabled value="" >--choose LGA--</option>
        <option v-for="(city, ctIndex) in lgaOptions" :key="ctIndex" 
        :value="city.id">{{city.name}}</option>
        </select>
    </div>
    <div class="col-12">
      <label class="form-label">Description</label>
      <textarea v-model="data.description" class="form-control"
      rows="2" placeholder="Write Description" name="description"></textarea>
    </div>
  </div>
  <div class="row mt-3 justify-content-end">
    <div class="col-12">
      <div class="d-flex mt-3 justify-content-end">
        <b-button variant="primary" 
        @click.prevent="editMode? update() : create()"
            type="button">{{editMode? 'Update':'Submit'}}</b-button>
      </div>
    </div>
  </div>
</form>
</template>

<script>
import { required, email, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  props:{
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {
        name: "",
        number: null,
        court_category_id: "",
        description: "",
        location: "",
        country_id: "",
        state_id: "",
        local_government_id: "",
      },
      mirrorData: null,
      stateOptions:[],
      lgaOptions:[]
    }
  },
  validations() {
    return {
      data:{
        name: {
          required: helpers.withMessage("Name is required", required),
        },
        court_category_id: {
          required: helpers.withMessage("Choose category", required),
        },
      }
    }
  },
  computed:{
    categoryList(){
      return this.$store.state.courtCategory.list
    },
    countryList(){
      return this.$store.state.countries
    }
  },
  methods: {
    create() {
        this.v$.$validate().then(result =>{
            if (!result) { this.alertError(this.v$.$errors[0].$message); return };
            let formData = new FormData();
            formData.append('data', JSON.stringify(this.data))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post('/courts/create', formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                  this.$emit('addItem', response.data.data)
                  this.$store.commit("court/ADD_ITEM", response.data.data)
                  this.resetForm();
                }
            })
        })
    },
    update(){
        this.v$.$validate().then(result =>{
            if (!result) { this.alertError(this.v$.$errors[0].$message); return };
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.data))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/courts/${this.editItem.id}/update`,formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                      this.$emit('updateItem', response.data.data)
                      this.resetForm()
                    }
                })
        
        })
    },
    countryChange(initialize = true){
      if(initialize){
        this.data.state_id = this.data.local_government_id = "";
        this.stateOptions = this.lgaOptions = [];
      }
      if(!this.data.country_id){
        return
      }
      this.$http.get(`${this.absoluteUrl()}/api/states?country_id=${this.data.country_id}`)
        .then((response) => {
          if(response.data.success){
            this.stateOptions = response.data.data
            this.stateChange(!this.editMode)
          }
      })
    },
    stateChange(initialize = true){
      if(initialize){
        this.data.local_government_id = "";
        this.lgaOptions = [];
      }
      if(!this.data.country_id || !this.data.state_id){
        return
      }
      this.$http.get(`${this.absoluteUrl()}/api/local-governments?country_id=${this.data.country_id}&state_id=${this.data.state_id}`)
        .then((response) => {
          if(response.data.success){
            this.lgaOptions = response.data.data
          }
      })
    },
    resetForm() {
      this.data = {...this.mirrorData}
      this.v$.$reset()
      this.$emit('closeMe')
    },
  },
  created(){
    this.$store.dispatch('courtCategory/fetchAllCategories')
    this.$store.dispatch('fetchCountries')
  },
  mounted(){
    this.mirrorData = JSON.parse(JSON.stringify(this.data))
    if(this.editMode){
      this.data = this.$filters.patchUpdatables(this.data, this.editItem)
      this.countryChange(false)
    }
  }
}
</script>
